import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import Form from 'react-dynamic-form'
import ProgressiveImage from 'react-progressive-image'
import { useMount, useSetState } from 'react-use'
import tw from 'tailwind.macro'
const Spinner = require('react-spinkit')
import NumberFormat from 'react-number-format'
import { media } from '../styles/utils'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import { Layout, Input } from '../components'
import {
    container,
    padding,
    button,
    bgClip,
    bgClipButton,
    hoverState,
} from '../styles/global'
import { parseACF } from '../utils'
import { postData } from '../services/api'
import { contactForm } from '../data/contact'

import { headerHeight } from '../components/Header/Header'
import { navigation } from '../styles/type'
import { orange } from '../styles/colors'

const Contact = props => {
    const data =
        props.previewData ||
        parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const wizardData = props.location?.state?.wizardData
    const contactFormRef = useRef(null)
    // console.log(data)
    // console.log(wizardData)

    // Number Props
    const numberProps = {
        prefix: '$',
        displayType: 'text',
        thousandSeparator: true,
        fixedDecimalScale: true,
        decimalScale: 0,
    }

    const [formState, updateForm] = useSetState({
        submitting: false,
        formComplete: false, // set to true for testing
        error: false,
    })

    const handleSubmit = (fields, resetForm, updateForm) => {
        const { formComplete } = fields

        const data = {
            first_name: fields.firstName.value,
            last_name: fields.lastName.value,
            business_name: fields.businessName.value,
            abn: fields.abn.value,
            business_address: fields.businessAddress.value,
            phone: fields.phone.value,
            email: fields.email.value,
        }

        // Append wizard data if passed via navigation state
        // and track event for GA
        if (wizardData) {
            console.log('includes wizard data')
            data.wizardData = wizardData
            trackCustomEvent({
                // string - required - The object that was interacted with
                category: "Contact Form Submission",
                // string - required - Type of interaction
                action: "Click",
                // string - optional - Useful for categorizing events
                label: "Submitted the form WITH Loan Wizard data",
            })
        } else {
            console.log('does not include wizard data')
            trackCustomEvent({
                // string - required - The object that was interacted with
                category: "Contact Form Submission",
                // string - required - Type of interaction
                action: "Click",
                // string - optional - Useful for categorizing events
                label: "Submitted the form WITHOUT Loan Wizard data",
            })
        }

        updateForm({
            submitting: true,
        })

        postData('/pda/contact', data)
            .then(res => {
                console.log(res)
                if (res.success) {
                    updateForm({
                        formComplete: true,
                        submitting: false,
                    })
                } else {
                    updateForm({
                        error: res.errors[0],
                        submitting: false,
                    })
                }

                resetForm()
            })
            .catch(error => {
                updateForm({
                    error: `Sorry, there's been an error while submitting the form. Please contact us directly at <a href="mailto:loans@strive.financial">loans@strive.financial</a> and we'll get back to you as soon as possible.`,
                    submitting: false,
                })
                resetForm()
            })
    }

    const renderSidebar = () => {
        return (
            <Sidebar>
                {data.sidebar_use_badge_icon && (
                    <Image
                        key={data.sidebar_badge_icon.id}
                        src={data.sidebar_badge_icon.sizes.large}
                    >
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    alt={data.sidebar_badge_icon?.alt}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                )}
                <Heading
                    dangerouslySetInnerHTML={{
                        __html: data.sidebar_intro_text,
                    }}
                />
                <Description
                    dangerouslySetInnerHTML={{
                        __html: data.sidebar_main_text,
                    }}
                />
            </Sidebar>
        )
    }

    const navigateToWizard = step => {
        navigate('/loan-selector', {
            state: {
                wizardData: {
                    selections: wizardData.selections,
                    activeStep: step,
                },
            },
        })
    }

    const renderWizardSummary = () => {
        const wizard_summary_items = wizardData.selections.steps.map(
            (item, i) => {
                return item.map((innerItem, inner_index) => {
                    // First Step
                    if (i === 0) {
                        if (inner_index === 0) {
                            return (
                                <Item key={inner_index}>
                                    <NumberFormat
                                        value={innerItem.amount}
                                        id="loan-amount"
                                        {...numberProps}
                                    />
                                    <ChangeStep
                                        onClick={() => navigateToWizard(i)}
                                    >
                                        Change
                                    </ChangeStep>
                                </Item>
                            )
                        } else {
                            return (
                                <Item key={inner_index}>
                                    <span>
                                        {`- ${innerItem.length} `}
                                        {innerItem.length > 1
                                            ? 'Months'
                                            : 'Month'}
                                    </span>

                                    <ChangeStep
                                        onClick={() => navigateToWizard(i)}
                                    >
                                        Change
                                    </ChangeStep>
                                </Item>
                            )
                        }

                        // Second Step
                    } else if (i === 1) {
                        return
                        // Other Steps
                    } else {
                        return (
                            <Item key={inner_index}>
                                <span>{`- ${innerItem.title}`}</span>
                                <ChangeStep onClick={() => navigateToWizard(i)}>
                                    Change
                                </ChangeStep>
                            </Item>
                        )
                    }
                })
            }
        )

        // strip html tags
        const recommendedPlan = wizardData.recommendedPlan.heading.replace(
            /(<([^>]+)>)/gi,
            ''
        )

        return (
            <WizardSummary>
                <Heading>Your loan details:</Heading>
                <Subheading
                    dangerouslySetInnerHTML={{
                        __html: recommendedPlan,
                    }}
                />
                <Grid>
                    <Items>{wizard_summary_items}</Items>
                </Grid>
            </WizardSummary>
        )
    }

    const renderContent = () => {
        const { formComplete } = formState

        return (
            <Content>
                {/* Subheading */}
                {!formComplete && (
                    <Subheading
                        dangerouslySetInnerHTML={{
                            __html: data.form_subheading,
                        }}
                    />
                )}

                {/* Heading */}
                {!formComplete && (
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: data.form_heading,
                        }}
                    />
                )}
                {formComplete && <Heading>Thanks!</Heading>}

                {/* Intro Text */}
                {!formComplete && (
                    <Info
                        dangerouslySetInnerHTML={{
                            __html: data.form_intro_text,
                        }}
                    />
                )}
                {formComplete && (
                    <Info>
                        Thanks for getting in touch with us. We’ve got your
                        details and we’ll contact you shortly.
                        <br />
                        In the meantime, if you’d like to learn more about the
                        services we offer, find out more about our Business
                        Loans loans <Link to={`/business-loans`}>here</Link>.
                        {/* Or
                        you can
                        {` `}
                        <ExternalLink
                            href={
                                'https://strive-appform.finpower.com.au/apply/'
                            }
                            target={`_blank`}
                            title={'Apply Now'}
                        >
                            apply now
                        </ExternalLink>{` `}
                        for a loan on our secure portal. */}
                    </Info>
                )}

                {/* Form */}
                {renderForm()}

                {/* Wizard Summary */}
                {wizardData && renderWizardSummary()}
            </Content>
        )
    }

    const renderForm = () => {
        const { formComplete, error } = formState

        return (
            <>
                {!formComplete && (
                    <FormWrapper>
                        <Form
                            ref={contactFormRef}
                            data={contactForm}
                            renderInput={<Input />}
                            moveToInvalidField={false}
                            styles={formStyles}
                            renderSubmit={false}
                            onSubmit={(fields, resetForm) =>
                                handleSubmit(fields, resetForm, updateForm)
                            }
                        />

                        <SubmitWrap>
                            {formState.submitting && (
                                <Spinner
                                    name="double-bounce"
                                    color={`${orange}`}
                                />
                            )}

                            <Button
                                disabled={formState.submitting ? true : false}
                                onClick={() => {
                                    contactFormRef.current &&
                                        contactFormRef.current.handleSubmit()
                                }}
                            >
                                <span>Submit</span>
                            </Button>
                        </SubmitWrap>
                    </FormWrapper>
                )}

                {!formComplete && error && (
                    <Error dangerouslySetInnerHTML={{ __html: error }} />
                )}
            </>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <BGColor>
                    <Container>
                        <Grid>
                            {renderSidebar()}
                            {renderContent()}
                        </Grid>
                    </Container>
                </BGColor>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Button = styled.div`
    ${props => {
        if (props.disabled)
            return css`
                pointer-events: none;
                opacity: 0.5 !important;
            `
    }}
`

const Image = styled(ProgressiveImage)``

const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const ExternalLink = styled(OutboundLink)``

// Layout

const Wrapper = styled.div`
    padding-top: calc(${headerHeight} + 2rem);
`

const Container = styled.div`
    ${container}
    ${padding}
	${tw`py-16`};

    ${media.mob`
        ${tw`pt-12`};
    `}
`

const Grid = styled.div`
    ${tw`flex`};

    ${media.mob`
        ${tw`flex-col`};
    `}
`

const BGColor = styled.div`
    ${tw`w-full h-full bg-lightBlue`};
`

// Sidebar

const Sidebar = styled.div`
    ${tw`w-1/3 pt-8 pr-8 text-darkBlue`};

    ${media.mob`
        ${tw`w-full mt-16 pt-0 pr-0`};
    `}

    ${Image}, img {
        ${media.mob`
            ${tw`mx-auto`};
        `}
    }

	${Heading}, ${Description} {
		max-width: 240px;

        ${media.mob`
            max-width: initial;
        `}
	}

    ${Heading} {
        ${tw`mt-10`};
        font-weight: 500; /* medium */
        font-size: 1.25rem;
        line-height: 1.22;
    }

    ${Description} {
        ${tw`mt-6`};
        font-weight: 400; /* regular */
        font-size: 14px;
        line-height: 1.58;
    }
`

// Form

const SubmitWrap = styled.div``
const FormWrapper = styled.div``
const Error = styled.div``
const Content = styled.div`
	${tw`w-2/3 p-8 pb-16 bg-white`};

    ${media.mob`
        ${tw`order-first w-full`};
        padding-left: 20px;
        padding-right: 20px;
    `}

	${Subheading}, ${Heading}, ${Info} {
		/* ${tw`max-w-md`}; */
	}

	${Subheading}, ${Info} {
		${tw`text-darkBlue`};
		font-weight: 600; /* semibold */
		font-size: 16px;
		line-height: 1.2;
		letter-spacing: -0.02em;
	}

	${Heading} {
		${tw`mt-4 text-orange`};
		font-weight: 600; /* semibold */
		font-size: 3.125rem;
		line-height: 1;
		letter-spacing: -0.04em;
	}

	${Info} { 
        ${tw`mt-6`};

        &, * {
            ${tw`leading-normal`};
        }

        a {
            ${tw`text-orange`};
            ${hoverState}
        }
	}

	${FormWrapper} {
		${tw`flex flex-col items-start mt-8`};
    }
    
    ${SubmitWrap} {
        ${tw`flex justify-end items-center w-full mt-4`};
    }

    ${Button} {
        ${tw`ml-4 cursor-pointer`};
        ${bgClipButton};
    }

	${Error} {
		${tw`mt-4 text-errorRed leading-normal`};
	}
`

// Loan Wizard Summary

const Items = styled.div``
const Item = styled.div``
const ChangeStep = styled.div``
const WizardSummary = styled.div`
    ${Grid} {
        ${tw`justify-between`};
    }

    ${Heading} {
        ${tw`mb-4 pb-2 border-b border-darkBlue text-darkBlue`};
        font-weight: 600; /* semibold */
        font-size: 15px;
        letter-spacing: -0.02em;
    }

    ${Subheading} {
        ${tw`mb-1 text-orange`};
        font-weight: 600; /* semibold */
        font-size: 1.5rem;
        line-height: 1.11;
    }

    ${Item} {
        ${tw`flex items-center`};

        span {
            ${tw`text-darkBlue`};
            font-weight: 600; /* semibold */
            font-size: 15px;
            line-height: 1.5;
            letter-spacing: -0.02em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &#loan-amount {
                font-size: 1.3125rem;
            }
        }

        ${ChangeStep} {
            ${tw`ml-2 text-orange cursor-pointer`};
            font-weight: 500; /* medium */
            font-size: 10px;
        }
    }
`

const formStyles = css`
    .dynamic-fields {
        ${tw`flex flex-wrap -mx-1`};
    }

    .dynamic-field {
        ${tw`w-full mb-4 px-1`};

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(6),
        &:nth-child(7) {
            ${tw`w-1/2`};

            ${media.mob`
                ${tw`w-full`};
            `}
        }
    }

    /* field validation error */
    .error {
        ${tw`mt-2`};
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "contact" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Contact
